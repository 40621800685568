import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import emailjs from 'emailjs-com';

import { getIsValidEmail } from 'Utils/getIsValidEmail';

import * as S from './ContactUs.styles';

type Props = {
    contactUsRef: React.RefObject<HTMLElement>;
};
type EventType = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

const inputTypes = {
    name: 'NAME',
    email: 'EMAIL',
    message: 'MESSAGE',
};

const ContactUs = ({ contactUsRef }: Props) => {
    const { t } = useTranslation();
    const [nameValue, setNameValue] = useState('');
    const [emailValue, setEmailValue] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [messageValue, setMessageValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isFormError, setIsFormError] = useState(false);
    const isSendButtonDisabled = !nameValue || !emailValue || !isValidEmail;

    const onInputChange = (type: string, event: EventType) => {
        const value = event.target.value;

        if (type === inputTypes.name) {
            setNameValue(value);
        } else if (type === inputTypes.email) {
            const isValidEmail = getIsValidEmail(emailValue);

            setEmailValue(value);
            setIsValidEmail(isValidEmail);
        } else if (type === inputTypes.message) {
            setMessageValue(value);
        }

        setIsFormError(false);
        setIsFormSubmitted(false);
    };

    const onFormSubmit = async () => {
        event?.preventDefault();

        const data = {
            from_name: nameValue,
            reply_to: emailValue,
            message: messageValue,
        };

        setIsLoading(true);

        emailjs
            .send(
                'service_wj29g2v',
                'template_ozz9pbt',
                data,
                'user_wT1s1GTA45CAB6kbMZ9bd'
            )
            .then((response) => {
                if (response.status === 200) {
                    setIsLoading(false);
                    setIsFormSubmitted(true);
                }
            })
            .catch(() => {
                setIsLoading(false);
                setIsFormError(true);
            });
    };

    return (
        <S.ContactUs ref={contactUsRef}>
            <S.FormWrapper>
                <S.Label>{t('ContactUs.Title')}</S.Label>
                <S.Form>
                    <S.Input
                        placeholder={t('ContactUs.Name')}
                        onChange={(e) => onInputChange(inputTypes.name, e)}
                        value={nameValue}
                    />
                    <S.Input
                        placeholder={t('ContactUs.Email')}
                        onChange={(e) => onInputChange(inputTypes.email, e)}
                        data-invalid={!isValidEmail}
                        value={emailValue}
                    />
                    <S.TextAre
                        placeholder={t('ContactUs.Message')}
                        onChange={(e) => onInputChange(inputTypes.message, e)}
                        value={messageValue}
                    />
                    <S.SubmitButton
                        isDisabled={
                            isSendButtonDisabled ||
                            isLoading ||
                            isFormSubmitted ||
                            isFormError
                        }
                        isActive={isLoading || isFormSubmitted}
                        isLoading={isLoading}
                        isSuccess={isFormSubmitted}
                        isError={isFormError}
                        onClick={onFormSubmit}
                        color="transparent"
                    >
                        {t('ContactUs.Submit')}
                    </S.SubmitButton>
                </S.Form>
            </S.FormWrapper>
        </S.ContactUs>
    );
};

export default ContactUs;
