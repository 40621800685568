import styled from '@emotion/styled';

import { fonts, devices } from 'Styles/Brand.styles';

export const Services = styled.section`
    text-align: center;
`;

export const Title = styled.h3`
    font-size: ${fonts.brandFontMedium};

    @media screen and (min-width: ${devices.mobile}) {
        font-size: ${fonts.brandFontSemi};
    }
`;

export const ServicesWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
`;
