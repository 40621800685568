import React, { ReactNode } from 'react';

import Loader from 'Shared/loader/Loader';
import Icon from 'Shared/Icon/Icon';

import * as S from './Button.styles';

type Props = {
    className?: string;
    onClick?: () => void;
    color?: string;
    isDisabled?: boolean;
    isLoading?: boolean;
    isSuccess?: boolean;
    isActive?: boolean;
    isError?: boolean;
    children: ReactNode;
};

const Button = ({
    className,
    onClick,
    color,
    isDisabled,
    isLoading,
    isSuccess,
    isError,
    children,
}: Props) => {
    return (
        <S.Button
            color={isError ? 'red' : color}
            className={className}
            onClick={onClick}
            disabled={isDisabled}
            data-error={isError}
        >
            {isLoading || isSuccess || isError ? (
                <>
                    {isLoading && <Loader />}
                    {isSuccess && <Icon name="success" />}
                    {isError && <Icon name="error" />}
                </>
            ) : (
                children
            )}
        </S.Button>
    );
};

export default Button;
