import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Button from 'Shared/button/Button';
import bgImage from 'Images/contactBG.webp';

import { colors, fonts, devices } from 'Styles/Brand.styles';

const inputStyles = css`
    padding: 13px 15px;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.6);
    outline: none;
`;

export const ContactUs = styled.section`
    display: flex;
    align-items: center;
    min-height: 100vh;
    background-image: url(${bgImage});
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: ${devices.desktop}) {
        background-position: right;
        background-size: contain;
    }
`;

export const FormWrapper = styled.div`
    width: 100%;
    max-width: 760px;
    border-radius: 5px;
    background-color: ${colors.brandColorRgba2};
`;

export const Form = styled.form`
    padding: 0 1em 1em;

    @media screen and (min-width: ${devices.mobile}) {
        padding: 0 3em 3em;
    }
`;

export const Label = styled.h3`
    padding-top: 32px;
    text-align: center;
    font-size: ${fonts.brandFontMedium};

    @media screen and (min-width: ${devices.mobile}) {
        font-size: ${fonts.brandFontSemi};
    }
`;

export const Input = styled.input`
    ${inputStyles};
    margin-bottom: 12px;

    &[data-invalid='true'] {
        border-color: red;
    }
`;

export const TextAre = styled.textarea`
    ${inputStyles};
    min-height: 150px;
`;

export const SubmitButton = styled(Button)`
    margin: 15px auto 0;
    min-width: 150px;
    height: 45px;
    color: ${colors.brandColor1};
    background-color: ${({ isActive }: { isActive?: boolean }) =>
        isActive && '#01602f'};
    text-transform: uppercase;
    border-radius: 5px;
    border-color: ${colors.brandColor1};

    &:hover:not(:disabled) {
        color: ${colors.brandColor3};
    }

    & svg {
        fill: ${colors.brandColor3};
    }
`;
