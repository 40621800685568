import React from 'react';

import Intro from './components/Intro/Intro';
import Services from './components/services/Services';
import Projects from './components/projects/Projects';
import ContactUs from './components/contactUs/ContactUs';

type Props = {
    servicesRef: React.RefObject<HTMLElement>;
    projectsRef: React.RefObject<HTMLElement>;
    contactUsRef: React.RefObject<HTMLElement>;
};

const App = ({ servicesRef, projectsRef, contactUsRef }: Props) => {
    return (
        <>
            <Intro servicesRef={servicesRef} />
            <Services servicesRef={servicesRef} />
            <Projects projectsRef={projectsRef} />
            <ContactUs contactUsRef={contactUsRef} />
        </>
    );
};

export default App;
