import React, { useRef } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Global } from '@emotion/react';

import { LanguageContext } from 'Utils/languagesContext';
import ScrollToTop from 'Utils//scrollToTop';

import { GlobalStyles } from 'Styles/Global.styles';
import Header from './components/header/Header';
import App from 'Entrypoints/app/App';
import Contracts from 'Entrypoints/contracts/Contracts';
import Footer from './components/footer/Footer';

const Client = () => {
    const { i18n } = useTranslation();
    const language = i18n.language || window.localStorage.i18nextLng;
    const servicesRef = useRef(null);
    const projectsRef = useRef(null);
    const contactUsRef = useRef(null);

    return (
        <Router>
            <ScrollToTop />
            <LanguageContext.Provider value={language}>
                <Global styles={GlobalStyles} />
                <Header
                    servicesRef={servicesRef}
                    projectsRef={projectsRef}
                    contactUsRef={contactUsRef}
                />
                <main>
                    <Switch>
                        <Route exact path="/">
                            <App
                                servicesRef={servicesRef}
                                projectsRef={projectsRef}
                                contactUsRef={contactUsRef}
                            />
                        </Route>
                        <Route exact path="/contracts" component={Contracts} />
                    </Switch>
                </main>
                <Footer />
            </LanguageContext.Provider>
        </Router>
    );
};

export default Client;
