import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LabelHoverEffect from 'Shared/labelHoverEffect/LabelHoverEffect';
import { scrollToElement } from 'Utils/scrollToElement';

import * as S from './Header.styles';

type Props = {
    servicesRef: React.RefObject<HTMLElement>;
    projectsRef: React.RefObject<HTMLElement>;
    contactUsRef: React.RefObject<HTMLElement>;
};

const Header = ({ servicesRef, projectsRef, contactUsRef }: Props) => {
    const { i18n, t } = useTranslation();
    const { pathname } = useLocation();
    const [isFixedView, setIsFixedView] = useState(false);
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const menuItems = t('Header.Menu', { returnObjects: true });

    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language);
    };

    const handleScrollEvent = () => {
        setIsFixedView(window.scrollY > 50);
    };

    const getIsDesktop = () => {
        return window.innerWidth >= 768;
    };

    const toggleMenu = () => {
        if (getIsDesktop()) {
            return;
        }

        setIsMenuVisible(!isMenuVisible);
        document.body.classList.toggle('scroll-disabled');
    };

    const onMenuItemClick = (ref: string) => {
        toggleMenu();

        const offset = getIsDesktop() ? 100 : 0;

        if (ref === 'serviceRef') {
            return scrollToElement(servicesRef?.current, offset);
        }

        if (ref === 'projectsRef') {
            return scrollToElement(projectsRef?.current, offset);
        }

        if (ref === 'contactUsRef') {
            return scrollToElement(contactUsRef?.current, 0);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScrollEvent);

        return () => {
            window.removeEventListener('scroll', handleScrollEvent);
        };
    });

    return (
        <S.Header isFixedView={isFixedView}>
            <S.MenuButton onClick={toggleMenu}>
                <S.MenuIcon name="menuButton" />
            </S.MenuButton>
            <Link to="/">
                <S.LogoIcon name="logo" />
                <S.LogoIcon name="logoName" />
            </Link>
            {pathname === '/' && (
                <S.NavMenu isVisible={isMenuVisible}>
                    <S.List>
                        {Object.keys(menuItems).map(
                            (item: string, index: number) => {
                                return (
                                    <S.MenuItem
                                        key={index}
                                        onClick={() =>
                                            onMenuItemClick(menuItems[item].Ref)
                                        }
                                    >
                                        <LabelHoverEffect>
                                            {menuItems[item].Title}
                                        </LabelHoverEffect>
                                    </S.MenuItem>
                                );
                            }
                        )}
                        <S.MenuItem>
                            <LabelHoverEffect
                                onClick={() => changeLanguage('en')}
                            >
                                en
                            </LabelHoverEffect>
                            /
                            <LabelHoverEffect
                                onClick={() => changeLanguage('et')}
                            >
                                et
                            </LabelHoverEffect>
                        </S.MenuItem>
                    </S.List>
                </S.NavMenu>
            )}
        </S.Header>
    );
};

export default Header;
