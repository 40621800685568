import styled from '@emotion/styled';

import { colors, fonts, devices } from 'Styles/Brand.styles';
import Icon from 'Shared/Icon/Icon';

export const Header = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2em 1em;
    z-index: 1;

    @media screen and (min-width: ${devices.mobile}) {
        padding: 2em 3em;
    }

    @media screen and (min-width: ${devices.tablet}) {
        background-color: ${({ isFixedView }: { isFixedView?: boolean }) =>
            isFixedView ? colors.brandColorRgba1 : 'transparent'};
        box-shadow: ${({ isFixedView }: { isFixedView?: boolean }) =>
            isFixedView ? 'rgb(24 20 22) 8px 0px 10px -4px' : 'none'};
        transition: background-color 0.5s ease;
    }
`;

export const MenuButton = styled.span`
    z-index: 9;

    @media screen and (min-width: ${devices.tablet}) {
        display: none;
    }
`;

export const MenuIcon = styled(Icon)`
    display: inline-block;

    @media screen and (min-width: ${devices.tablet}) {
        display: none;
    }
`;

export const LogoIcon = styled(Icon)`
    display: none;
    height: 36px;
    width: auto;
    margin-right: 12px;

    @media screen and (min-width: ${devices.tablet}) {
        display: inline-block;
    }
`;

export const List = styled.ul`
    list-style: none;
    padding: 0;

    @media screen and (min-width: ${devices.tablet}) {
        display: flex;
        margin: 0;
    }
`;

export const MenuItem = styled.li`
    padding: 12px 0;

    @media screen and (min-width: ${devices.tablet}) {
        display: flex;
        margin-left: 20px;
        padding: 0;
        font-weight: ${fonts.brandFontBold};

        & span {
            margin: 0 4px;
        }
    }
`;

export const NavMenu = styled.nav`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    color: ${colors.brandColor3};
    background: ${colors.brandColor2};
    transform: ${({ isVisible }: { isVisible?: boolean }) =>
        isVisible ? 'translateY(0%);' : 'translateY(-100%);'};
    transition: transform 0.6s ease;

    @media screen and (min-width: ${devices.tablet}) {
        position: unset;
        width: unset;
        height: unset;
        color: unset;
        background: unset;
        transform: unset;
    }
`;
