import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'i18n';

import Client from 'client/Client';

ReactDOM.render(
    <Suspense fallback={<div>Loading</div>}>
        <Client />
    </Suspense>,
    document.getElementById('root')
);
