import React from 'react';

import { getIconViewBox, getIconPath } from './Icons';

import * as S from './Icon.styles';

type Props = {
    name: string;
    className?: string;
};

const Icon = ({ name, className }: Props) => {
    return (
        <S.Icon
            className={className}
            width="100%"
            height="100%"
            viewBox={getIconViewBox(name)}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            {getIconPath(name)}
        </S.Icon>
    );
};

export default Icon;
