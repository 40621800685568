export const scrollToElement = (
    element: HTMLElement | null,
    offset?: number
) => {
    const offsetY = element?.offsetTop || 0;

    return window.scrollTo({
        top: offsetY - (offset || 0),
        behavior: 'smooth',
    });
};
