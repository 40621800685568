import React from 'react';
import { useTranslation } from 'react-i18next';

import { scrollToElement } from 'Utils/scrollToElement';

import Button from 'Shared/button/Button';

import * as S from './Intro.styles';

type Props = {
    servicesRef: React.RefObject<HTMLElement>;
};

const Intro = ({ servicesRef }: Props) => {
    const { t } = useTranslation();

    return (
        <S.Intro>
            <div>
                <S.Title>{t('Intro.Title')}</S.Title>
                <p>{t('Intro.Description')}</p>
                <Button
                    onClick={() => scrollToElement(servicesRef?.current, 100)}
                >
                    {t('Intro.CTA')}
                </Button>
            </div>
        </S.Intro>
    );
};

export default Intro;
