import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LabelHoverEffect from 'Shared/labelHoverEffect/LabelHoverEffect';

import * as S from './Footer.styles';

const currentYear = new Date().getFullYear();

const Footer = () => {
    const { t } = useTranslation();

    return (
        <S.Footer>
            <S.FlexWrapper>
                <S.FlexLogoWrapper>
                    <S.LogoIcon name="logo" />
                    <S.LogoIcon name="logoName" />
                </S.FlexLogoWrapper>
                <S.FlexInfoWrapper>
                    <S.Label>{t('Footer.Company')}</S.Label>
                    <p>
                        <Link to="/contracts">
                            <LabelHoverEffect>
                                {t('Footer.Contract')}
                            </LabelHoverEffect>
                        </Link>
                    </p>
                    <p>
                        <Link to="/contracts">
                            <LabelHoverEffect>
                                {t('Footer.TermsAndConditions')}
                            </LabelHoverEffect>
                        </Link>
                    </p>
                </S.FlexInfoWrapper>
                <S.FlexInfoWrapper>
                    <S.Label>{t('Footer.Contact')}</S.Label>
                    <p>
                        <a
                            href="mailto:info@civilengineering.ee"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <LabelHoverEffect>
                                info@civilengineering.ee
                            </LabelHoverEffect>
                        </a>
                    </p>
                    <p>
                        <a
                            href="tel:+37256470310"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <LabelHoverEffect>+372 5647 0310</LabelHoverEffect>
                        </a>
                    </p>
                    <p>
                        <a
                            href="http://maps.google.com/?q=1200%20Kadaka%20tee%205,%20Tallinn"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <LabelHoverEffect>
                                Kadaka tee 5, Tallinn
                            </LabelHoverEffect>
                        </a>
                    </p>
                </S.FlexInfoWrapper>
            </S.FlexWrapper>
            <S.CenteredWrapper>
                <p>
                    {t('Footer.CopyRight')} {currentYear} Structure Engineering
                    OU
                </p>
                <p>{t('Footer.RightsReserved')}</p>
            </S.CenteredWrapper>
        </S.Footer>
    );
};

export default Footer;
