import React from 'react';
import styled from '@emotion/styled';

const StyledSection = styled.section`
    display: flex;
    align-items: center;
    min-height: 100vh;
`;

const Contracts = () => {
    return <StyledSection>Contracts page data</StyledSection>;
};

export default Contracts;
