import { css } from '@emotion/react';
import { devices } from 'Styles/Brand.styles';

export const GlobalStyles = css`
    body {
        font-family: 'Montserrat', sans-serif;

        &.scroll-disabled {
            overflow: hidden;
        }
    }
    * {
        box-sizing: border-box;
    }
    a {
        text-decoration: none;
        color: inherit;
    }
    section,
    footer {
        padding: 5em 1em 0;
    }

    @media screen and (min-width: ${devices.mobile}) {
        section,
        footer {
            padding: 5em 3em 0;
        }
    }
`;
