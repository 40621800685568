import React from 'react';

import * as S from './Service.styles';

const Service = () => {
    return (
        <S.Service>
            <S.Title>daaaaaa</S.Title>
            <p>asdasdasdasdasdasdasd</p>
        </S.Service>
    );
};

export default Service;
