import React from 'react';
import { useTranslation } from 'react-i18next';

import Service from './Service/Service';

import * as S from './Services.styles';

type Props = {
    servicesRef: React.RefObject<HTMLElement>;
};

const Services = ({ servicesRef }: Props) => {
    const { t } = useTranslation();

    return (
        <S.Services ref={servicesRef}>
            <S.Title>{t('Services.Title')}</S.Title>
            <S.ServicesWrapper>
                <Service />
                <Service />
                <Service />
                <Service />
            </S.ServicesWrapper>
        </S.Services>
    );
};

export default Services;
