import styled from '@emotion/styled';

import Icon from 'Shared/Icon/Icon';
import { colors, fonts, devices } from 'Styles/Brand.styles';

export const Footer = styled.footer`
    color: ${colors.brandColor3};
    background-color: ${colors.brandColor1};
`;

export const FlexWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    max-width: 1200px;

    @media screen and (min-width: ${devices.mobile}) {
        flex-wrap: nowrap;
        justify-content: space-between;
    }
`;

export const FlexLogoWrapper = styled.div`
    flex-basis: 100%;
    margin-bottom: 3em;
    text-align: center;

    @media screen and (min-width: ${devices.mobile}) {
        flex-basis: unset;
        margin-bottom: 0;
        text-align: left;
    }
`;

export const FlexInfoWrapper = styled.div`
    flex-basis: 45%;

    & span {
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media screen and (min-width: ${devices.mobile}) {
        flex-basis: unset;

        & span {
            max-width: unset;
        }
    }
`;

export const LogoIcon = styled(Icon)`
    height: 40px;
    width: auto;
    margin-right: 12px;
    fill: ${colors.brandColor3};
`;

export const Label = styled.h4`
    margin-top: 0;
    font-size: ${fonts.brandFontMedium};
    color: ${colors.brandColor2};

    @media screen and (min-width: ${devices.mobile}) {
        font-size: ${fonts.brandFontSemi};
    }
`;

export const CenteredWrapper = styled.div`
    text-align: center;
    padding: 3em 0;
    color: #d9dbe1;
`;
