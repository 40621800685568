/* colors */
const brandColor1 = '#111111';
const brandColor2 = '#00793B';
const brandColor3 = '#fff';

const brandColorRgba1 = 'rgba(255, 255, 255, 0.9)';
const brandColorRgba2 = 'rgba(0, 121, 59, 0.3)';

export const colors = {
    brandColor1,
    brandColor2,
    brandColor3,
    brandColorRgba1,
    brandColorRgba2,
};

/* fonts */
const brandFontSmall = '14px';
const brandFontDefault = '16px';
const brandFontMedium = '20px';
const brandFontSemi = '24px';
const brandFontLarge = '40px';

const brandFontSemiBold = '500';
const brandFontBold = '700';

export const fonts = {
    brandFontSmall,
    brandFontDefault,
    brandFontMedium,
    brandFontSemi,
    brandFontLarge,
    brandFontSemiBold,
    brandFontBold,
};

/* devices */
const mobile = '576px';
const tablet = '768px';
const desktop = '1280px';

export const devices = {
    mobile,
    tablet,
    desktop,
};
