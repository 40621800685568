/* eslint-disable max-len */
import React from 'react';

export const getIconViewBox = (name: string) => {
    switch (name) {
        case 'logo':
            return '0 0 120 47';
        case 'logoName':
            return '0 0 175 47';
        case 'success':
            return '0 0 52 52';
        case 'error':
            return '0 0 256 256';
        case 'menuButton':
            return '0 0 70 70';
        default:
            return '';
    }
};

export const getIconPath = (name: string) => {
    switch (name) {
        case 'logo':
            return (
                <>
                    <path d="M55.0851 9.61727V0H0V6.96533V9.61727V18.6914V24.6023V28.3086H45.4303V37.3508H0V47H55.0851V43.3576V37.3508V28.3086V25.7206V18.6914H9.62348V9.61727H55.0851Z"></path>
                    <path d="M119.575 0H64.4898V9.61727H119.575V0Z"></path>
                    <path
                        d="M119.575 18.6914H64.4898V28.3087H119.575V18.6914Z"
                        fill="#00793B"
                    ></path>
                    <path d="M119.575 37.3508H64.4898V46.9681H119.575V37.3508Z"></path>
                </>
            );
        case 'logoName':
            return (
                <>
                    <path d="M0.40155 18.2195L3.21029 16.8932C3.48431 17.8705 3.96385 18.6383 4.67174 19.1502C5.37964 19.6621 6.29305 19.9181 7.38914 19.9181C8.43956 19.9181 9.26163 19.6156 9.87818 19.0339C10.4947 18.4522 10.7916 17.6378 10.7916 16.6606C10.7916 15.3576 9.74117 14.2174 7.61749 13.1936C7.32063 13.054 7.09228 12.9377 6.93243 12.8679C4.5804 11.6812 2.98193 10.5876 2.20554 9.63366C1.4063 8.67967 1.0181 7.49301 1.0181 6.12021C1.0181 4.32858 1.61182 2.8627 2.82209 1.74584C4.00952 0.628986 5.58515 0.0705566 7.52615 0.0705566C9.12462 0.0705566 10.4491 0.373039 11.5452 1.00127C12.6184 1.6295 13.372 2.53695 13.783 3.70035L11.0428 5.14295C10.6089 4.44492 10.1294 3.93302 9.627 3.63054C9.10179 3.30479 8.50807 3.14192 7.84584 3.14192C6.9096 3.14192 6.15604 3.39786 5.58515 3.88649C5.03711 4.39838 4.74025 5.07315 4.74025 5.9108C4.74025 7.23706 5.97335 8.47026 8.41673 9.61039C8.59941 9.70346 8.75926 9.77326 8.8506 9.8198C10.9971 10.8203 12.4586 11.7976 13.2578 12.7748C14.057 13.7288 14.4452 14.9387 14.4452 16.3581C14.4452 18.4522 13.8058 20.1042 12.5042 21.3374C11.2026 22.6171 9.44431 23.2221 7.25213 23.2221C5.40247 23.2221 3.89535 22.78 2.73075 21.9191C1.54331 21.0582 0.766914 19.825 0.40155 18.2195Z"></path>
                    <path d="M27.37 3.60661V22.6165H23.8534V3.60661H18.0989V0.581787H33.1016V3.62988H27.37V3.60661Z"></path>
                    <path d="M41.1853 12.7742V22.6397H37.897V0.581787H42.8066C44.2452 0.581787 45.3641 0.674859 46.1634 0.861002C46.9626 1.04714 47.6248 1.3729 48.1957 1.81498C48.8579 2.35015 49.3832 3.02491 49.7485 3.88583C50.1139 4.72347 50.2966 5.65419 50.2966 6.6547C50.2966 8.42306 49.8855 9.79587 49.0406 10.7964C48.1957 11.7969 46.9626 12.4019 45.3185 12.588L52.7628 22.6165H48.7894L41.5963 12.7509H41.1853V12.7742ZM41.4137 9.98201H42.053C43.9255 9.98201 45.1586 9.7726 45.7752 9.33051C46.3917 8.88842 46.6886 8.09731 46.6886 6.98045C46.6886 5.77053 46.3689 4.90961 45.7067 4.42099C45.0445 3.93236 43.8342 3.67642 42.053 3.67642H41.4137V9.98201Z"></path>
                    <path d="M56.4621 0.581787H59.9331V13.9841C59.9331 15.9619 60.3898 17.4743 61.2804 18.4748C62.1938 19.4753 63.5411 19.9639 65.3222 19.9639C67.1034 19.9639 68.4506 19.4753 69.3412 18.4748C70.2546 17.4743 70.6885 15.9851 70.6885 13.9841V0.581787H74.2051V14.3098C74.2051 17.2881 73.4744 19.5218 71.9901 21.011C70.5058 22.5001 68.2908 23.2447 65.2994 23.2447C62.3308 23.2447 60.0929 22.5001 58.6315 21.0343C57.17 19.5684 56.4393 17.3114 56.4393 14.3331V0.581787H56.4621Z"></path>
                    <path d="M97.3144 5.93331C96.3096 5.0724 95.2592 4.4209 94.1631 3.97881C93.0442 3.53672 91.8796 3.32731 90.6693 3.32731C88.2944 3.32731 86.3763 4.09515 84.892 5.6541C83.4077 7.18978 82.677 9.21408 82.677 11.6572C82.677 14.0305 83.4077 16.0083 84.8463 17.544C86.2849 19.0797 88.1118 19.8708 90.3039 19.8708C91.5827 19.8708 92.8158 19.6381 93.9576 19.1727C95.1222 18.7074 96.2183 18.0093 97.2915 17.0786V21.1272C96.3553 21.8253 95.3049 22.3372 94.2088 22.6862C93.0898 23.0352 91.8796 23.1981 90.578 23.1981C88.911 23.1981 87.381 22.9189 85.9881 22.3604C84.5723 21.802 83.3392 20.9876 82.2888 19.9173C81.2383 18.8702 80.4163 17.6138 79.8454 16.1944C79.2745 14.7518 79.0005 13.2394 79.0005 11.6107C79.0005 9.98192 79.2745 8.46951 79.8454 7.05017C80.4163 5.63083 81.2383 4.37436 82.3116 3.30404C83.3849 2.21045 84.618 1.39607 86.0109 0.837644C87.4039 0.279215 88.911 0 90.5323 0C91.8111 0 93.0213 0.186143 94.1631 0.558429C95.3049 0.930715 96.4466 1.51241 97.5199 2.28025L97.3144 5.93331Z"></path>
                    <path d="M110.787 3.60661V22.6165H107.271V3.60661H101.516V0.581787H116.519V3.62988H110.787V3.60661Z"></path>
                    <path d="M121.223 0.581787H124.694V13.9841C124.694 15.9619 125.151 17.4743 126.041 18.4748C126.955 19.4753 128.302 19.9639 130.083 19.9639C131.864 19.9639 133.211 19.4753 134.102 18.4748C135.015 17.4743 135.449 15.9851 135.449 13.9841V0.581787H138.966V14.3098C138.966 17.2881 138.235 19.5218 136.751 21.011C135.267 22.5001 133.052 23.2447 130.06 23.2447C127.092 23.2447 124.854 22.5001 123.392 21.0343C121.931 19.5684 121.177 17.3114 121.177 14.3331V0.581787H121.223Z"></path>
                    <path d="M147.141 12.7742V22.6397H143.853V0.581787H148.762C150.201 0.581787 151.32 0.674859 152.119 0.861002C152.918 1.04714 153.58 1.3729 154.151 1.81498C154.814 2.35015 155.339 3.02491 155.704 3.88583C156.069 4.72347 156.252 5.65419 156.252 6.6547C156.252 8.42306 155.841 9.79587 154.996 10.7964C154.151 11.7969 152.918 12.4019 151.274 12.588L158.718 22.6165H154.745L147.552 12.7509H147.141V12.7742ZM147.369 9.98201H148.009C149.881 9.98201 151.114 9.7726 151.731 9.33051C152.347 8.88842 152.644 8.09731 152.644 6.98045C152.644 5.77053 152.325 4.90961 151.662 4.42099C151 3.93236 149.79 3.67642 148.009 3.67642H147.369V9.98201Z"></path>
                    <path d="M162.92 22.6397V0.581787H175V3.62988H166.437V9.16764H175V12.3088H166.437V19.4288H175V22.6397H162.92V22.6397Z"></path>
                    <path d="M1.08661 46.4193V33.0403H8.41674V34.8784H3.23313V38.229H8.43958V40.137H3.23313V44.4648H8.43958V46.4193H1.08661Z"></path>
                    <path d="M16.0437 46.4193V32.5051L24.4014 40.6954C24.6298 40.9281 24.8581 41.184 25.0865 41.44C25.3148 41.6959 25.566 41.9984 25.8172 42.3242V33.0403H27.7811V46.9545L19.2635 38.6246C19.0351 38.3919 18.8068 38.1592 18.6013 37.9033C18.3958 37.6473 18.1902 37.3914 17.9847 37.1122V46.4193H16.0437V46.4193Z"></path>
                    <path d="M42.8523 39.5555H48.0587C48.0587 39.6486 48.0587 39.7649 48.0587 39.9511C48.0815 40.3932 48.0815 40.6724 48.0815 40.8353C48.0815 42.72 47.5563 44.1858 46.4831 45.2329C45.4326 46.2799 43.9255 46.7918 42.0073 46.7918C40.9341 46.7918 39.975 46.629 39.1301 46.3032C38.2852 45.9775 37.5545 45.4888 36.9151 44.8373C36.2985 44.1858 35.819 43.4413 35.4765 42.5571C35.1339 41.6962 34.9741 40.7422 34.9741 39.7649C34.9741 38.7644 35.1339 37.8104 35.4765 36.9728C35.819 36.1119 36.3214 35.3673 36.9836 34.7158C37.623 34.0643 38.3765 33.5989 39.2443 33.2499C40.112 32.9242 41.0254 32.738 42.0073 32.738C43.0349 32.738 43.9712 32.9242 44.7932 33.3197C45.6153 33.7153 46.346 34.297 46.9854 35.0881L45.4326 36.2748C44.9988 35.7396 44.4964 35.3673 43.9255 35.1114C43.3775 34.8554 42.7381 34.7158 42.053 34.7158C40.6372 34.7158 39.4726 35.1812 38.5592 36.1119C37.6687 37.0426 37.2119 38.2525 37.2119 39.7417C37.2119 41.2541 37.6687 42.4873 38.5592 43.418C39.4498 44.3487 40.6372 44.8373 42.053 44.8373C43.2633 44.8373 44.2452 44.5581 44.9303 43.9764C45.6382 43.3947 45.9807 42.6036 45.9807 41.6031V41.417H42.8294V39.5555H42.8523Z"></path>
                    <path d="M70.0719 46.4193V32.5051L78.4297 40.6954C78.658 40.9281 78.8864 41.184 79.1147 41.44C79.3431 41.6959 79.5943 41.9984 79.8454 42.3242V33.0403H81.8093V46.9545L73.2917 38.6246C73.0634 38.3919 72.835 38.1592 72.6295 37.9033C72.424 37.6473 72.2185 37.3914 72.0129 37.1122V46.4193H70.0719V46.4193Z"></path>
                    <path d="M89.3905 46.4193V33.0403H96.7206V34.8784H91.5142V38.229H96.7206V40.137H91.5142V44.4648H96.7206V46.4193H89.3905Z"></path>
                    <path d="M104.348 46.4193V33.0403H111.678V34.8784H106.471V38.229H111.678V40.137H106.471V44.4648H111.678V46.4193H104.348Z"></path>
                    <path d="M120.903 40.4163V46.3961H118.894V33.0171H121.862C122.73 33.0171 123.415 33.0636 123.895 33.18C124.374 33.2963 124.785 33.4824 125.128 33.7617C125.539 34.0874 125.858 34.5062 126.064 35.0181C126.292 35.53 126.406 36.0885 126.406 36.6934C126.406 37.7637 126.155 38.6014 125.653 39.2063C125.151 39.8113 124.397 40.1836 123.392 40.2999L127.914 46.3961H125.493L121.132 40.4163H120.903V40.4163ZM121.04 38.741H121.428C122.57 38.741 123.301 38.6014 123.689 38.3454C124.054 38.0662 124.237 37.6009 124.237 36.9261C124.237 36.1815 124.032 35.6696 123.643 35.3671C123.255 35.0647 122.502 34.9251 121.428 34.9251H121.04V38.741V38.741Z"></path>
                    <path d="M134.422 46.4193V33.0403H136.568V46.4193H134.422Z"></path>
                    <path d="M57.8779 46.4193V33.0403H60.0244V46.4193H57.8779Z"></path>
                    <path d="M144.789 46.4193V32.5051L153.147 40.6954C153.375 40.9281 153.603 41.184 153.832 41.44C154.06 41.6959 154.311 41.9984 154.562 42.3242V33.0403H156.526V46.9545L148.009 38.6246C147.78 38.3919 147.552 38.1592 147.346 37.9033C147.141 37.6473 146.935 37.3914 146.73 37.1122V46.4193H144.789V46.4193Z"></path>
                    <path d="M169.748 39.5555H174.954C174.954 39.6486 174.954 39.7649 174.954 39.9511C174.977 40.3932 174.977 40.6724 174.977 40.8353C174.977 42.72 174.452 44.1858 173.379 45.2329C172.328 46.2799 170.821 46.7918 168.903 46.7918C167.83 46.7918 166.87 46.629 166.026 46.3032C165.181 45.9775 164.45 45.4888 163.811 44.8373C163.194 44.1858 162.714 43.4413 162.372 42.5571C162.029 41.6962 161.87 40.7422 161.87 39.7649C161.87 38.7644 162.029 37.8104 162.372 36.9728C162.714 36.1119 163.217 35.3673 163.879 34.7158C164.518 34.0643 165.272 33.5989 166.14 33.2499C167.007 32.9242 167.921 32.738 168.903 32.738C169.93 32.738 170.867 32.9242 171.689 33.3197C172.511 33.7153 173.242 34.297 173.881 35.0881L172.328 36.2748C171.894 35.7396 171.392 35.3673 170.821 35.1114C170.273 34.8554 169.634 34.7158 168.948 34.7158C167.533 34.7158 166.368 35.1812 165.455 36.1119C164.564 37.0426 164.107 38.2525 164.107 39.7417C164.107 41.2541 164.564 42.4873 165.455 43.418C166.345 44.3487 167.533 44.8373 168.948 44.8373C170.159 44.8373 171.141 44.5581 171.826 43.9764C172.534 43.3947 172.876 42.6036 172.876 41.6031V41.417H169.725V39.5555H169.748Z"></path>
                </>
            );
        case 'success':
            return (
                <>
                    <path
                        d="M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M26,50C12.767,50,2,39.233,2,26
		S12.767,2,26,2s24,10.767,24,24S39.233,50,26,50z"
                    />
                    <path
                        d="M38.252,15.336l-15.369,17.29l-9.259-7.407c-0.43-0.345-1.061-0.274-1.405,0.156c-0.345,0.432-0.275,1.061,0.156,1.406
		l10,8C22.559,34.928,22.78,35,23,35c0.276,0,0.551-0.114,0.748-0.336l16-18c0.367-0.412,0.33-1.045-0.083-1.411
		C39.251,14.885,38.62,14.922,38.252,15.336z"
                    />
                </>
            );
        case 'error':
            return (
                <>
                    <g transform="translate(128 128) scale(0.72 0.72)">
                        <g transform="translate(-175.05 -175.05000000000004) scale(3.89 3.89)">
                            <path
                                d="M 85.429 85.078 H 4.571 c -1.832 0 -3.471 -0.947 -4.387 -2.533 c -0.916 -1.586 -0.916 -3.479 0 -5.065 L 40.613 7.455 C 41.529 5.869 43.169 4.922 45 4.922 c 0 0 0 0 0 0 c 1.832 0 3.471 0.947 4.386 2.533 l 40.429 70.025 c 0.916 1.586 0.916 3.479 0.001 5.065 C 88.901 84.131 87.261 85.078 85.429 85.078 z M 45 7.922 c -0.747 0 -1.416 0.386 -1.79 1.033 L 2.782 78.979 c -0.373 0.646 -0.373 1.419 0 2.065 c 0.374 0.647 1.042 1.033 1.789 1.033 h 80.858 c 0.747 0 1.416 -0.387 1.789 -1.033 s 0.373 -1.419 0 -2.065 L 46.789 8.955 C 46.416 8.308 45.747 7.922 45 7.922 L 45 7.922 z M 45 75.325 c -4.105 0 -7.446 -3.34 -7.446 -7.445 s 3.34 -7.445 7.446 -7.445 s 7.445 3.34 7.445 7.445 S 49.106 75.325 45 75.325 z M 45 63.435 c -2.451 0 -4.446 1.994 -4.446 4.445 s 1.995 4.445 4.446 4.445 s 4.445 -1.994 4.445 -4.445 S 47.451 63.435 45 63.435 z M 45 57.146 c -3.794 0 -6.882 -3.087 -6.882 -6.882 V 34.121 c 0 -3.794 3.087 -6.882 6.882 -6.882 c 3.794 0 6.881 3.087 6.881 6.882 v 16.144 C 51.881 54.06 48.794 57.146 45 57.146 z M 45 30.239 c -2.141 0 -3.882 1.741 -3.882 3.882 v 16.144 c 0 2.141 1.741 3.882 3.882 3.882 c 2.14 0 3.881 -1.741 3.881 -3.882 V 34.121 C 48.881 31.98 47.14 30.239 45 30.239 z"
                                transform=" matrix(1 0 0 1 0 0) "
                                strokeLinecap="round"
                            />
                        </g>
                    </g>
                </>
            );
        case 'menuButton':
            return (
                <>
                    <path d="M70 0H0V14H70V0Z" />
                    <path d="M70 28H0V42H70V28Z" fill="#00793B" />
                    <path d="M35 56H0V70H35V56Z" />
                </>
            );
        default:
            return <path />;
    }
};
