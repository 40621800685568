import styled from '@emotion/styled';

import { fonts, devices } from 'Styles/Brand.styles';
import bgImage from 'Images/introBg.webp';

export const Intro = styled.section`
    display: flex;
    align-items: center;
    min-height: 100vh;
    background-image: url(${bgImage});
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: ${devices.desktop}) {
        background-size: contain;
    }
`;

export const Title = styled.h2`
    margin: 0;

    @media screen and (min-width: ${devices.mobile}) {
        font-size: ${fonts.brandFontLarge};
    }
`;
