import styled from '@emotion/styled';
import { colors } from 'Styles/Brand.styles';

export const LabelHoverEffect = styled.span`
    display: inline-block;
    cursor: pointer;

    &:after {
        content: '';
        display: block;
        margin: auto;
        height: 3px;
        background-color: transparent;
        width: 0;
        transition: width 0.5s ease, background-color 0.5s ease;
    }

    &:hover {
        &:after {
            background-color: ${colors.brandColor2};
            width: 100%;
        }
    }
`;
