import styled from '@emotion/styled';
import { colors } from 'Styles/Brand.styles';

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 45px;
    color: ${colors.brandColor3};
    text-decoration: none;
    border: 1px solid ${colors.brandColor2};
    background-color: ${({ color }: { color?: string }) =>
        color ? color : colors.brandColor2};
    transition: all 0.4s ease;
    cursor: pointer;
    outline: none;

    &[data-error='true'] {
        &:hover {
            background-color: darkred;
        }
    }

    &:hover:not(:disabled) {
        background-color: #01602f;
    }

    &:disabled {
        cursor: not-allowed;
    }
`;
