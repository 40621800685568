import React, { ReactNode } from 'react';

import * as S from './LabelHoverEffect.styles';

type Props = {
    children: ReactNode;
    onClick?: () => void;
};

const LabelHoverEffect = ({ children, onClick }: Props) => {
    return (
        <S.LabelHoverEffect onClick={onClick}>{children}</S.LabelHoverEffect>
    );
};

export default LabelHoverEffect;
