import styled from '@emotion/styled';

import { fonts } from 'Styles/Brand.styles';

export const Service = styled.div`
    flex-basis: 100%;
    max-width: 650px;
    padding-top: 2em;

    @media (min-width: 576px) {
        flex-basis: 45%;
    }
`;

export const Title = styled.p`
    font-weight: ${fonts.brandFontBold};
`;
