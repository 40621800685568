import React from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import * as S from './Projects.styles';

type Props = {
    projectsRef: React.RefObject<HTMLElement>;
};

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
        slidesToSlide: 1,
    },
};

const Services = ({ projectsRef }: Props) => {
    const { t } = useTranslation();

    return (
        <S.Projects ref={projectsRef}>
            <S.Title>{t('Projects.Title')}</S.Title>
            <Carousel responsive={responsive} swipeable={true}>
                <div>Item 1</div>
                <div>Item 2</div>
                <div>Item 3</div>
                <div>Item 4</div>
            </Carousel>
        </S.Projects>
    );
};

export default Services;
